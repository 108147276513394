import { Injectable } from '@angular/core'
import type { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { API_ROUTES } from './api-routes'
import { APIServiceService } from './apiservice.service'

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    constructor(private api: APIServiceService) {}

    getConfig(locId: number): Observable<XConfig> {
        return this.api
            .get<XConfigResponse>(API_ROUTES.v3.getConfig + '?location_id=' + locId)
            .pipe(map((r) => r.configuration))
    }
}

export interface XConfigResponse {
    configuration: XConfig
}

export interface XConfig {
    TIP_PERC_1: string
    TIP_PERC_2: string
    TIP_PERC_3: string
    WEB_PAY_LIMIT: string
    REDIRECT_TO_MENU_URL: string
    REDIRECT_TO_CHECKS_FOUND_URL: string
    ALCOHOL_MAJOR_GROUPS: string
}
