import type {
    AnimationMetadata,
    AnimationTriggerMetadata,
} from '@angular/animations'
import {
    animateChild,
    group,
    query,
    trigger,
    animate,
    style,
    transition,
} from '@angular/animations'

export function animateChildrenOnEnterAnimation(
    options?: any
): AnimationTriggerMetadata {
    return trigger((options && options.anchor) || 'animateChildrenOnEnter', [
        transition(':enter', [query('@*', animateChild(), { optional: true })]),
    ])
}

export function slideToRight(): AnimationMetadata[] {
    return [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ left: '100%' })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(
                ':leave',
                [animate('300ms ease-out', style({ left: '-100%' }))],
                { optional: true }
            ),
            query(
                ':enter',
                [animate('300ms ease-out', style({ left: '0%' }))],
                { optional: true }
            ),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]
}

export function slideUp(): AnimationMetadata[] {
    return [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ top: 1000 })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(
                ':leave',
                [animate('200ms ease-out', style({ opacity: 0 }))],
                { optional: true }
            ),
            query(':enter', [animate('500ms ease-out', style({ top: 0 }))], {
                optional: true,
            }),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]
}

export function slideDown(): AnimationMetadata[] {
    return [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ top: -1000 })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(
                ':leave',
                [animate('200ms ease-out', style({ opacity: 0 }))],
                { optional: true }
            ),
            query(':enter', [animate('500ms ease-out', style({ top: 0 }))], {
                optional: true,
            }),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]
}

export function slideToLeft(): AnimationMetadata[] {
    return [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ left: '-100%' })], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(
                ':leave',
                [animate('300ms ease-out', style({ left: '100%' }))],
                { optional: true }
            ),
            query(
                ':enter',
                [animate('300ms ease-out', style({ left: '0%' }))],
                { optional: true }
            ),
        ]),
        query(':enter', animateChild(), { optional: true }),
    ]
}
