export const environment = {
  production: true,
  googleAPIKey: "AIzaSyD8E5ScX2arcU2K9BvhzCchs8yMTWRd180",
  squareUrl: "https://web.squarecdn.com/v1/square.js",
  merchantId: 2,
  processor: "SQUARE",
  brand: "JOEY",
  title: "JOEY",
  giftCardEnable: true,
  takeoutEnable: false,
  giftingEnable: true,
  giftingMessageEnable: true,
  giftingMessage: "Give the gift of JOEY today!",
  payEnable: true,
  dineInMenuEnable: true,
  dineInMenuId: 4,
  takeoutMenuId: 1,
  loginEnable: true,
  modalShort: 3000,
  modalLong: 5000,
  feeFreeThreshold: 80,
  feeFreeHintThreshold: 0,
  feeMenuId: 14,
  feeDsvcSeq: 184,
  feeMenuCustPayUnder: 15118,
  discountRefetchDelay: 5000,
  payTipDefaultPercent: 18,
  payTipPercentages: [15, 18, 20],
  deliveryDriverTipDefaultPercent: 15,
  deliveryDriverTipPercentages: [5, 10, 15],
  deliveryDriverTipMax: 10,
  deliveryLocationTipDefaultPercent: 0,
  deliveryLocationTipPercentages: [5, 10, 15],
  takeoutLocationTipDefaultPercent: 15,
  takeoutLocationTipPercentages: [12, 15, 20],
  driverTipDsvcSeq: 181,
  locationTipDsvcSeq: 179,
  
  titleFull: "",
  mapsAPIKey: "AIzaSyD8E5ScX2arcU2K9BvhzCchs8yMTWRd180",
  iosAppUrl: "https://itunes.apple.com/ca/app/joey-pay/id757375945",
  androidAppUrl:
    "https://play.google.com/store/apps/details?id=redsprite.joeypay.ca",
  firebase: {
    apiKey: "AIzaSyBkNQm5ezGrFZvzT9pc9DRdh-aX7QBWqoM",
    authDomain: "joey-drive-integration.firebaseapp.com",
    projectId: "joey-drive-integration",
    storageBucket: "joey-drive-integration.appspot.com",
    messagingSenderId: "926101691552",
    appId: "1:926101691552:web:182983c18acbb4f00b7dc5",
    measurementId: "G-ZP3YGTJL4V",
  },
  dcimages: {
    steak: "/assets/dcimages/steak1.jpg",
    wine: "/assets/dcimages/alcohol1.jpg",
    bubbles: "/assets/dcimages/alcohol1.jpg",
    beer: "/assets/dcimages/beer1.jpg",
    spirits: "/assets/dcimages/spirits1.jpg",
    happy: "/assets/dcimages/shares2.jpg",
    liquor: "/assets/dcimages/spirits1.jpg",
    game: "/assets/dcimages/gameday1.jpg",
    shares: "/assets/dcimages/shares1.jpg",
    bowls: "/assets/dcimages/bowls1.jpg",
    plant: "/assets/dcimages/salads1.jpg",
    burgers: "/assets/dcimages/burgers1.jpg",
    sweets: "/assets/dcimages/dessert1.jpg",
    kits: "/assets/dcimages/kits1.jpg",
    market: "/assets/dcimages/bakery1.jpg",
    kids: "/assets/dcimages/kids1.jpg",
  },
  alcoholProhibitedMajorGroups: ["LIQUOR"],
  alcoholProhibitedLocationIds: [18, 22, 42],
  alcoholProhibitedMenuIds: [1],
  terms: 'https://www.xpotech.net/privacy-policy-joey-pay/',
};
