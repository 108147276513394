import {
    AfterViewInit,
    Component,
    HostListener,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core'
import { MatSidenav } from '@angular/material/sidenav'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs'
import { User } from 'src/app/api/login.service'
import { AuthenticationService } from '../../authentication.service'

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, AfterViewInit {
    @Input()
    title1: string

    @Input()
    title2: string

    @Input()
    showLogo = true

    open = false

    currentModule: string

    @ViewChild('sidenav')
    sidenav: MatSidenav

    currentUser: User = null

    requestInfo: string

    requestDelete: string

    color = 'transparent'

    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((route) => {
                const mod = route['url'].split('/')[1]
                if (mod === 'account') {
                    this.currentModule = route['url'].split()[0]
                } else {
                    this.currentModule = mod
                }
            })

        this.authService.currentUser.subscribe((user) => {
            this.currentUser = user
            if (user) {
                const requestInfo = {
                    subject: `Request for account information ${user.email}`,
                    body: 'Hello, \n\nI would like to receive a copy of the personal information that you hold associated with my account. \n\nThank you.',
                }
                const requestDelete = {
                    subject: `Account deletion request for ${user.email}`,
                    body: 'Hello, \n\nI would like to permanently close my account and delete my data. \n\nThank you.',
                }
                this.requestInfo = this.buildEmailHref(requestInfo)
                this.requestDelete = this.buildEmailHref(requestDelete)
            }
        })
    }

    private buildEmailHref(request: UserEmail) {
        return `mailto:privacy@xpotech.net?subject=${encodeURIComponent(
            request.subject
        )}&body=${encodeURIComponent(request.body)}`
    }

    handleRedirect(route: string) {
        // close sidebar even if currentRoute === nextRoute
        this.router.navigateByUrl(route)
        this.sidenav.close()
    }

    ngAfterViewInit() {
        //  Drawer open state observable is too slow, set to open or closed immediately
        this.sidenav.openedStart.subscribe(() => {
            this.open = true
            this.color = 'primary'
        })
        this.sidenav.closedStart.subscribe(() => {
            this.open = false
            this.color = 'transparent'
        })
    }

    logout() {
        this.authService.logout()
        this.sidenav.close()
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll() {
        window.scrollY > 20
            ? (this.color = 'primary')
            : (this.color = 'transparent')
    }
}

interface UserEmail {
    subject: string
    body: string
}
