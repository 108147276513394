import type { ErrorHandler } from '@angular/core'
import { Injectable } from '@angular/core'
import { EventService } from './event.service'
import { LoggingService } from './logging.service'
import { HttpErrorResponse } from '@angular/common/http'
import { CartServiceService } from './cart-service.service'
import { environment } from '../environments/environment'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private cart: CartServiceService,
        private eventService: EventService,
        private logger: LoggingService
    ) {}

    handleError(error: any) {
        console.log('HandleError', error)

        let message: string = error.message ? error.message : error.toString()

        try {
            if (message.includes('object Object')) {
                message = JSON.stringify(error)
            }
        } catch (x) {}

        // Reload on chunk load error
        if (message.includes('ChunkLoadError')) {
            console.log('Reloading due to ChunkLoadError')
            window.location.reload()
            return
        }
        if (message.includes('The database connection is closing')) {
            console.log('Reloading due to IndexedDB connection closed')
            window.location.reload()
            return
        }

        console.log('Captured', message, error)
        this.eventService.exception(message, true)

        if (error instanceof Error) {
            this.logger.error(
                'GlobalErrorHandler',
                'Error:' +
                    error.message +
                    ' Name:' +
                    error.name +
                    ' Stack:' +
                    error.stack
            )
        } else if (error instanceof HttpErrorResponse) {
            this.logger.error(
                'GlobalErrorHandler',
                'HTTPError:' +
                    error.message +
                    ' Name:' +
                    error.name +
                    ' StatusText:' +
                    error.statusText +
                    ' StatusCode:' +
                    error.status
            )
        } else {
            this.logger.error(
                'GlobalErrorHandler',
                'NonError:' +
                    message +
                    ' Type:' +
                    error.constructor?.name +
                    ' toStr:' +
                    error
            )
        }

        if (environment.production) {
            // Clear data - start over
            this.cart.reset().subscribe()
        }

        throw error
    }
}
