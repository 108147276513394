import { Injectable } from '@angular/core'
import type { Observable } from 'rxjs'
import { API_ROUTES } from './api-routes'
import { APIServiceService } from './apiservice.service'
import type {
    RestaurantLocationMin,
    RevenueCenterMin,
} from './location.service'

@Injectable({
    providedIn: 'root',
})
export class TableService {
    constructor(private api: APIServiceService) {}

    getTablesByNFC(nfc: string): Observable<TableMin[]> {
        const url = API_ROUTES.public.tables + '?nfc='
        return this.api.get<TableMin[]>(url + nfc)
    }

    getAllTables(): Observable<TableMin[]> {
        const url = API_ROUTES.public.tables
        return this.api.get<TableMin[]>(url)
    }

    getTableById(id: number): Observable<Table> {
        const url = API_ROUTES.public.tables + '/'
        return this.api.get<Table>(url + id)
    }
}

export interface Table extends TableMin {
    readonly location: RestaurantLocationMin
    readonly revenue_center: RevenueCenterMin
    readonly seats: number
}

export interface TableMin {
    readonly id: number
    readonly name: string
}
