import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeaderImageComponent } from './header-image.component'

@NgModule({
    declarations: [HeaderImageComponent],
    imports: [CommonModule],
    exports: [HeaderImageComponent],
})
export class HeaderImageModule {}
