import { Component } from '@angular/core'
import { environment } from 'src/environments/environment'
import { TitleService } from '../../title.service'
@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
    brand = environment.brand + ' Logo'

    constructor(public titleService: TitleService) {}
}
