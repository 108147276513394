import { ElementRef, OnInit, ViewChild } from '@angular/core'
import { Component, HostBinding } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'
import { environment } from '../environments/environment'
import { slideToRight, slideToLeft, slideUp } from './router.animations'
import { NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { transition, trigger } from '@angular/animations'
import { TitleService } from './title.service'
import { LogglyService } from 'ngx-loggly-logger'
import { filter } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TermsSnackComponent } from './widget/terms-snack/terms-snack.component'

@Component({
    selector: 'app-root',
    animations: [
        trigger('routeAnimation', [
            transition('* => LandingPage', slideUp()),

            transition('LandingPage => TakeoutLandingPage', slideToRight()),
            transition(
                'TakeoutLandingPage => DeliveryInfoPage',
                slideToRight()
            ),
            transition('DeliveryInfoPage => MenuPage', slideToRight()),
            transition('LandingPage => MenuPage', slideToRight()),
            transition('MenuPage => MenuGroupPage', slideToRight()),
            transition('MenuGroupPage => MenuPage', slideToLeft()),
            transition('MenuGroupPage => ItemConfigPage', slideToRight()),
            transition('ItemConfigPage => MenuGroupPage', slideToLeft()),
            transition('MenuGroupPage => CartPage', slideToRight()),
            transition('CartPage => MenuPage', slideToLeft()),
            transition('MenuPage => CartPage', slideToRight()),
            transition('CartPage => MenuGroupPage', slideToLeft()),
            transition('CartPage => CheckoutPage', slideToRight()),
            transition('CheckoutPage => CartPage', slideToLeft()),
            transition('CheckoutPage => TakeoutCompletedPage', slideToRight()),

            transition('LandingPage => MenuLanding', slideToRight()),
            transition('MenuLanding => MenuView', slideToRight()),
            transition('MenuView => MenuView', slideToRight()),
            transition('MenuView => MenuLanding', slideToLeft()),

            transition('LandingPage => PayEnterCode', slideToRight()),
            transition('PayEnterCode => PayView', slideToRight()),
            transition('PayView => PayEnterCode', slideToLeft()),
            transition('PayChoose => PayView', slideToRight()),
            transition('PayView => PayChoose', slideToLeft()),
            transition('PayView => PayCC', slideToRight()),
            transition('PayCC => PayView', slideToLeft()),
            transition('PayCC => PayReceipt', slideToRight()),
            transition('PayReceipt => PayView', slideToLeft()),

            transition('LandingPage => AuthLogin', slideToRight()),
            transition('LandingPage => TransactionHistoryView', slideToRight()),
            transition('PayEnterCode => TransactionHistoryView', slideToLeft()),
            transition(
                'TransactionHistoryView => PayEnterCode',
                slideToRight()
            ),
            transition('PayEnterCode => PayCC', slideToRight()),
            transition('TransactionHistoryView => AuthSignup', slideToLeft()),
            transition('TransactionHistoryView => AuthLogin', slideToLeft()),
            transition('AuthLogin => TransactionHistoryView', slideToRight()),
            transition('AuthLogin => AuthSignup', slideToLeft()),
            transition('AuthSignup => AuthLogin', slideToRight()),
            transition('AuthSignup => TransactionHistoryView', slideToRight()),
            transition('* => AuthSignup', slideUp()),
            transition('* => AuthLogin', slideUp()),
            transition('* => TransactionHistoryView', slideUp()),
            transition('* => PayEnterCode', slideUp()),
            transition('* => AuthResetPassword', slideUp()),
        ]),
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @HostBinding('class') componentCssClass

    @ViewChild('routeContainer')
    routeContainer: ElementRef

    isLanding = false

    constructor(
        public titleService: TitleService,
        public logglyService: LogglyService,
        public overlayContainer: OverlayContainer,
        private router: Router,
        public snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle('', '')

        const theme = environment.brand
        this.overlayContainer.getContainerElement().classList.add(theme)
        this.componentCssClass = theme

        this.logglyService.push({
            logglyKey: '429b4e29-dfff-4de7-bf6f-992ccd2f4c72',
            sendConsoleErrors: false,
            tag: 'xpoWeb',
        })
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((route) => {
                const basePath = route['url']
                this.isLanding = basePath === '/'
            })
        if (!localStorage.getItem('accepted-policy')) {
            this.snackBar.openFromComponent(TermsSnackComponent, {
                verticalPosition: 'bottom',
                horizontalPosition: 'left',
            })
        }
    }

    openApp() {}

    // Need overflow hidden to prevent scroll bars appearing during
    // animations but cant have overflow: hidden with sticky toolbar in menu module
    // So add overflow only when animating
    animationStart() {
        this.scrollToTop()
        this.routeContainer.nativeElement.style.overflow = 'hidden'
    }

    animationEnd() {
        this.routeContainer.nativeElement.style.overflow = 'visible'
    }

    scrollToTop() {
        window.scrollTo(0, 0)
    }

    prepRouteState(outlet: RouterOutlet) {
        return (
            outlet &&
            outlet.activatedRouteData &&
            outlet.activatedRouteData['animation']
        )
    }
}
