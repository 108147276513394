import { finalize, tap } from 'rxjs/operators'
import { NgxSpinnerService } from 'ngx-spinner'
import type {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http'
import { HttpErrorResponse } from '@angular/common/http'
import type { Observable, Subscription } from 'rxjs'
import { timer } from 'rxjs'
import { Injectable } from '@angular/core'
import { LoggingService } from './logging.service'

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    private count = 0

    constructor(
        private spinner: NgxSpinnerService,
        private logger: LoggingService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let sub: Subscription = null

        console.log('Intercept http. count=', this.count)
        if (this.count === 0) {
            const source = timer(1000)
            sub = source.subscribe((val) => {
                console.log('Intercept http. In Timer. count=', this.count)
                if (this.count > 0) {
                    console.log('Intercept http. In Timer. Show')
                    this.spinner.show()
                }
            })
        }

        this.count++

        return next.handle(request).pipe(
            tap(
                () => {},
                (e) => {
                    if (e instanceof HttpErrorResponse) {
                        this.logger.warn(
                            'HTTPInteceptor',
                            'Req: ' +
                                request.url +
                                ' Resp: ' +
                                e.status +
                                ' ' +
                                e.error +
                                ' ' +
                                JSON.stringify(e)
                        )
                    } else {
                        this.logger.warn(
                            'HTTPInteceptor',
                            'Req: ' +
                                request.url +
                                ' Resp: ' +
                                JSON.stringify(e)
                        )
                    }
                }
            ),
            finalize(() => {
                this.count--

                if (this.count === 0) {
                    // Unsubscribe
                    if (!!sub) {
                        sub.unsubscribe()
                    }

                    console.log('Intercept http. Hide')
                    this.spinner.hide()
                }
            })
        )
    }
}
