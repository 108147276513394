export const API_ROUTES = {
    public: {
        passwordReset: '/api/public/users/password_reset',
        passwordUpdate: '/api/public/users/password_update',
        checks: '/api/public/checks',
        giftCardProviders: '/api/public/giftcards/providers',
        giftCardBalance: '/api/public/giftcards/balance',
        locations: '/api/public/locations',
        tables: '/api/public/tables',
        paymentNonce: '/api/public/payment/nonce',
        paymentGiftCard: '/api/public/payment/giftcard',
        transactions: '/api/public/transactions',
        cashRecord: '/api/public/payment/cash_record',
    },
    v0: {
        logout: '/api/v0/logout',
        login: '/api/v0/login',
        memberDiscount: '/api/v0/getmemberdiscountcard',
        applyDiscount: '/api/v0/addmemberdiscount',
        userAddresses: '/api/v0/user_addresses',
    },
    v1: {
        createAccount: 'api/v1/account',
        processor: '/api/v1/processor',
    },
    v2: {
        getCheckById: '/api/v2/getcheckID',
        anonLogin: '/api/v2/anonLogin',
    },
    v3: {
        getConfig: '/api/v3/getconfig',
        locationCheck: '/api/v3/location_check',
        loginLocations: '/api/v3/loginlocations',
        locations: '/api/v3/locations',
        menuName: '/api/v3/menu/name',
        menu: '/api/v3/menu/',
        location: '/api/v3/location',
        checks: '/api/v3/checks',
    },
} as const
