<mat-toolbar
    #nav
    color="{{ color }}"
    class="nav"
    [ngStyle]="{ 'justify-content': (showLogo || open) ? 'space-between' : 'flex-end' }"
>
    <app-logo *ngIf="showLogo || open"></app-logo>
    <div class="flex">
        <button
            mat-button
            (click)="sidenav.toggle()"
            class="nav__button"
            [attr.aria-label]="'Toggle Menu'"
        >
            <mat-icon class="nav__button-icon" *ngIf="!open">menu</mat-icon>
            <mat-icon class="nav__button-icon" *ngIf="open">close</mat-icon>
        </button>
    </div>
    <ul class="nav__menu">
        <li class="nav__menu-link-wrapper" (click)="handleRedirect('/pay')">
            <a
                class="nav__menu-link"
                [ngClass]="{
                    'nav__menu-link--active': currentModule === 'pay'
                }"
                mat-button
                >Pay</a
            >
        </li>
        <li
            class="nav__menu-link-wrapper"
            (click)="handleRedirect('/transaction-history')"
        >
            <a
                class="nav__menu-link"
                [ngClass]="{
                    'nav__menu-link--active':
                        currentModule === 'transaction-history'
                }"
                mat-button
                >Transaction History</a
            >
        </li>
        <ng-container *ngIf="!currentUser">
            <li
                class="nav__menu-link-wrapper"
                (click)="handleRedirect('/account/login')"
            >
                <a
                    class="nav__menu-link"
                    [ngClass]="{
                        'nav__menu-link--active':
                            currentModule === '/account/login'
                    }"
                    mat-button
                    >Log In</a
                >
            </li>
            <li
                class="nav__menu-link-wrapper"
                (click)="handleRedirect('/account/signup')"
            >
                <a
                    class="nav__menu-link"
                    [ngClass]="{
                        'nav__menu-link--active':
                            currentModule === '/account/signup'
                    }"
                    mat-button
                    >Sign Up</a
                >
            </li>
        </ng-container>
        <ng-container *ngIf="currentUser">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>account_circle</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <div class="nav__user">
                    <h1 class="nav__user-name">
                        {{ currentUser.firstname }} {{ currentUser.lastname }}
                    </h1>
                    <h1 class="nav__email">{{ currentUser.email }}</h1>
                </div>
                <button
                    mat-menu-item
                    (click)="handleRedirect('/account/my_account')"
                >
                    My Account
                </button>
                <button mat-menu-item [matMenuTriggerFor]="privacy">
                    Privacy
                </button>
                <button mat-menu-item (click)="logout()">Log out</button>
            </mat-menu>
            <mat-menu #menu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="privacy">
                    Privacy
                </button>
            </mat-menu>
            <mat-menu #privacy="matMenu">
                <a [href]="requestInfo" target="_top" mat-menu-item
                    >Request Account Information</a
                >
                <a [href]="requestDelete" target="_top" mat-menu-item
                    >Request Account Deletion</a
                >
            </mat-menu>
        </ng-container>
    </ul>
</mat-toolbar>
<mat-sidenav-container autosize>
    <mat-sidenav
        #sidenav
        class="nav__sidebar"
        [fixedInViewport]="0"
        [fixedTopGap]="0"
        [fixedBottomGap]="0"
        position="end"
    >
        <aside class="nav__content">
            <h1 class="nav__heading">{{ title1 }}</h1>
            <h1 *ngIf="currentUser">{{ currentUser.firstname }} {{ currentUser.lastname }}</h1>
            <ul class="nav__options">
                <li
                    class="nav__link"
                    [ngClass]="{ 'nav__link--active': currentModule === 'pay' }"
                    (click)="handleRedirect('/pay')"
                >
                    <a>Pay Bill</a>
                </li>
                <li
                    class="nav__link"
                    [ngClass]="{
                        'nav__link--active':
                            currentModule === 'transaction-history'
                    }"
                    (click)="handleRedirect('/transaction-history')"
                >
                    <a>Transaction History</a>
                </li>
                <ng-container *ngIf="!currentUser">
                    <li
                        class="nav__link"
                        [ngClass]="{
                            'nav__link--active':
                                currentModule === '/account/signup'
                        }"
                        (click)="handleRedirect('/account/signup')"
                    >
                        <a>Sign Up</a>
                    </li>
                    <li
                        class="nav__link"
                        [ngClass]="{
                            'nav__link--active':
                                currentModule === '/account/login'
                        }"
                        (click)="handleRedirect('/account/login')"
                    >
                        <a>Log In</a>
                    </li>
                </ng-container>
                <ng-container *ngIf="currentUser">
                    <li class="nav__link" [matMenuTriggerFor]="account">
                        <a>Account</a>
                    </li>
                    <mat-menu #account="matMenu">
                        <button
                            mat-menu-item
                            (click)="handleRedirect('/account/my_account')"
                        >
                            My Account
                        </button>
                        <button mat-menu-item [matMenuTriggerFor]="privacy">
                            Privacy
                        </button>
                        <button mat-menu-item (click)="logout()">
                            Log Out
                        </button>
                    </mat-menu>
                    <mat-menu #privacy="matMenu">
                        <a [href]="requestInfo" target="_top" mat-menu-item
                            >Request Account Information</a
                        >
                        <a [href]="requestDelete" target="_top" mat-menu-item
                            >Request Account Deletion</a
                        >
                    </mat-menu>
                </ng-container>
            </ul>
        </aside>
    </mat-sidenav>
    <ng-content></ng-content>
</mat-sidenav-container>
