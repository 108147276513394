<main>
    <div class="filter"></div>
    <section>
        <app-logo></app-logo>
        <div class="button-wrap">
            <button mat-button routerLink="/menu" *ngIf="enableDineIn">
                View Menu
            </button>
            <button mat-button routerLink="/pay" *ngIf="enablePay">
                Pay Bill
            </button>
            <button mat-button routerLink="/takeout" *ngIf="enableTakeout">
                Pickup & Delivery
            </button>
        </div>
        <div class="bottom-logo">
            <p>Powered by</p>
            <img fetchPriority="high" alt="XPO" src="/assets/xpo-logo.png" />
        </div>
    </section>
</main>
