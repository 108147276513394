import { Injectable } from '@angular/core'
import { LogglyService } from 'ngx-loggly-logger'
import { StorageMap } from '@ngx-pwa/local-storage'
import { DeviceDetectorService } from 'ngx-device-detector'
import { VERSION } from '../environments/version'
import { environment } from '../environments/environment'
import type { XLogin, UserResponse } from './api/login.service'
import type { Check, XStructuredCheck } from './api/check.service'
import type { Cart } from './cart-service.service'

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    mdcData = {} as any

    constructor(
        private loggly: LogglyService,
        private storage: StorageMap,
        private device: DeviceDetectorService
    ) {
        this.storage.get('logging').subscribe((x) => {
            if (!!x) {
                this.mdcData = x
            }
        })
    }

    clear() {
        delete this.mdcData.checkSeq
        delete this.mdcData.checkNum
        delete this.mdcData.checkId
        delete this.mdcData.location
        delete this.mdcData.table
        delete this.mdcData.rvcseq
        delete this.mdcData.code
        delete this.mdcData.orderId
        this.storage.set('logging', this.mdcData).subscribe()
    }

    addAnonUser(user: XLogin) {
        this.mdcData.user = '' + user.id
        this.storage.set('logging', this.mdcData).subscribe()
    }

    addUser(user: UserResponse) {
        this.mdcData.user = '' + user.id + ' [' + user.firstname + ' ' + user.lastname + ']'
        this.storage.set('logging', this.mdcData).subscribe()
    }
    

    addCheck(check: Check) {
        this.mdcData.checkNum = check.check_num
        this.mdcData.checkId = check.check_name
        this.mdcData.table = check.table?.name
        this.storage.set('logging', this.mdcData).subscribe()
    }

    addCheckStructured(check: XStructuredCheck) {
        this.mdcData.checkSeq = check.checkSeq
        this.mdcData.checkNum = check.checkNum
        this.mdcData.checkId = check.checkName
        this.mdcData.table = check.tableNum
        this.storage.set('logging', this.mdcData).subscribe()
    }

    addCart(cart: Cart) {
        this.mdcData.orderId = cart.uuid
        if (!!cart.location) {
            this.mdcData.location = cart.location.name
        }

        this.storage.set('logging', this.mdcData).subscribe()
    }
    // Some endpoints like getLocationResultByLatLong return XLocation not new interface,
    // for now all types passed have an id and a name. Type could be improved later
    addLocation<T extends Pick<T, 'name'>>(loc: T) {
        this.mdcData.location = loc.name
        this.storage.set('logging', this.mdcData).subscribe()
    }

    addTable(tablenum: string) {
        this.mdcData.table = tablenum
        this.storage.set('logging', this.mdcData).subscribe()
    }

    addRvc(rvcseq: string) {
        this.mdcData.rvcseq = rvcseq
        this.storage.set('logging', this.mdcData).subscribe()
    }

    info(caller: string, msg: string, other?: any) {
        this.log(caller, 'INFO', msg, other)
    }

    warn(caller: string, msg: string, other?: any) {
        this.log(caller, 'WARN', msg)
    }

    error(caller: string, msg: string, other?: any) {
        this.log(caller, 'ERROR', msg)
    }

    private log(
        caller: string,
        levelVal: string,
        msg: string,
        other: any = {}
    ) {
        try {
            if (window.location.hostname === 'localhost') {
                return
            }
            const urlVal = window.location.href
            if (
                msg.includes('FirebaseError') &&
                msg.includes('Cookies are not enabled')
            ) {
                return
            }
            if (msg.includes('loggly.com')) {
                return
            }
            if (msg.includes('getcheck?code=') && msg.includes('404')) {
                return
            }

            this.mdcData.brand = environment.brand

            const deviceOSVal =
                this.device.os +
                (this.device.os_version !== 'unknown'
                    ? ' ' + this.device.os_version
                    : '')
            const deviceBrowserVal =
                this.device.browser + ' ' + this.device.browser_version
            const deviceTypeVal =
                (this.device.isDesktop() ? 'Desktop' : '') +
                (this.device.isTablet() ? 'Tablet' : '') +
                (this.device.isMobile() ? 'Mobile' : '')

            const data = {
                level: levelVal,
                mdc: this.mdcData,
                logger: caller,
                message: msg,
                xpoWeb: {
                    path: urlVal,
                    version: VERSION.version + '-' + VERSION.hash,
                    specific: other,
                    device: {
                        browser: deviceBrowserVal,
                        os: deviceOSVal,
                        brand: this.device.device,
                        type: deviceTypeVal,
                        res: window.innerWidth + 'x' + window.innerHeight,
                    },
                },
            }
            this.loggly.push(data)
        } catch (e) {
            // no worries - Dont let logging stop us!
        }
    }
}
