import { Injectable } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { map, shareReplay, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { EventService } from '../event.service'
import { LoggingService } from '../logging.service'
import { API_ROUTES } from './api-routes'
import { APIServiceService } from './apiservice.service'

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    private locationCache$: Observable<XLocation[]> = this.getLocationsRaw(
        environment.brand
    ).pipe(
        tap((locs) => {
            locs.forEach((l) => {
                l.special_hours_upcoming = l.special_hours.filter((x) => {
                    const time = new Date(x.special_date).getTime()
                    const now = new Date().getTime() - 1000 * 60 * 60 * 24 * 2 // Two days;
                    const future = now + 1000 * 60 * 60 * 24 * 30 // One month;
                    return time > now && time < future
                })

                // TODO: This should come from the server
                if (l.address.country === 'CA') {
                    l.address.countryFull = 'Canada'
                } else if (l.address.country === 'US') {
                    l.address.countryFull = 'United States'
                } else {
                    l.address.provstateFull = 'Not Set'
                }

                if (l.address.provstate === 'BC') {
                    l.address.provstateFull = 'British Columbia'
                } else if (l.address.provstate === 'AB') {
                    l.address.provstateFull = 'Alberta'
                } else if (l.address.provstate === 'MB') {
                    l.address.provstateFull = 'Manitoba'
                } else if (l.address.provstate === 'ON') {
                    l.address.provstateFull = 'Ontario'
                } else if (l.address.provstate === 'WA') {
                    l.address.provstateFull = 'Washington'
                } else if (l.address.provstate === 'CA') {
                    l.address.provstateFull = 'California'
                } else {
                    l.address.provstateFull = 'Not Set'
                }

                if (!l.address.metro_city) {
                    l.address.metro_city = l.address.city
                }
            })
        }),
        shareReplay(1)
    )

    constructor(
        private api: APIServiceService,
        private logger: LoggingService,
        private eventService: EventService
    ) {}

    public getLocationGPS(): Observable<XGPSLocationResponse> {
        return new Observable((o) => {
            if (navigator.geolocation) {
                this.logger.info('APIService', 'Geo Location supported')

                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.logger.info(
                            'APIService',
                            'Geo Location is: ' +
                                position.coords.latitude +
                                ' ' +
                                position.coords.longitude
                        )
                        console.log('Geo Location pos:', position)

                        this.eventService.custom('GPS', {
                            status: 'success',
                            coords: position.coords,
                        })

                        this.getLocationResultByLatLong(
                            position.coords.latitude,
                            position.coords.longitude
                        ).subscribe(
                            (e) => o.next(e),
                            (x) => o.error(x),
                            () => o.complete()
                        )
                    },
                    (error) => {
                        this.logger.info('APIService', 'Geo Location disabled')
                        console.log('Geo Location pos: err', error)
                        this.eventService.custom('GPS', {
                            status: 'refused',
                        })
                        o.complete()
                    },
                    {}
                )
            } else {
                this.logger.info('APIService', 'Geo Location not supported')
                this.eventService.custom('GPS', {
                    status: 'notSupported',
                })
                o.complete()
            }
        })
    }

    public getLocationResultByLatLong(
        lat: number,
        long: number
    ): Observable<XGPSLocationResponse> {
        return this.api.get<XGPSLocationResponse>(
            API_ROUTES.v3.locationCheck +
                '?brand=' +
                environment.brand +
                '&latitude=' +
                lat +
                '&longitude=' +
                long
        )
    }

    public getLocationById(id: number): Observable<RestaurantLocation> {
        const url = API_ROUTES.public.locations + '/'
        return this.api.get(url + id)
    }

    public getAllLocations(): Observable<RestaurantLocation[]> {
        const url = API_ROUTES.public.locations
        return this.api.get<RestaurantLocation[]>(url)
    }

    public getLocationsByLatLong(
        lat: number,
        long: number
    ): Observable<XLocation[]> {
        const closest$ = this.getLocationResultByLatLong(lat, long).pipe(
            map((resp) => resp.nearest)
        )

        return combineLatest([closest$, this.getLocations()]).pipe(
            map(([closest, all]) => {
                return closest.map((cl) => all.filter((l) => l.id === cl.id)[0])
            })
        )
    }

    private getLocationsRaw(brand: string): Observable<XLocation[]> {
        return this.api.get<XLocation[]>(
            API_ROUTES.v3.loginLocations + '?brand=' + brand
        )
    }

    getLocations(): Observable<XLocation[]> {
        return this.locationCache$
    }

    getLocation(locId: number): Observable<XLocation> {
        return this.locationCache$.pipe(
            map((loc) => loc.filter((l) => l.id === locId)[0])
        )
    }
}

export interface XGPSLocationResponse {
    result: boolean
    distance: number
    location: XLocation
    nearest: XLocation[]
}

export interface XLocation {
    id: number
    name: string
    fullname: string
    brand: string
    distance: number
    address: XLocationAddress
    hours: XLocationHour[]
    special_hours: XLocationSpecialHour[]
    currency: string
    square_location_id: string

    delivery_options_description: string
    delivery_options: XDeliveryOption[]

    // Extended
    special_hours_upcoming: XLocationSpecialHour[]
}

export interface XDeliveryOption {
    description: string
    provider_display_name: string
    storefront_url: string
}

export interface XLocationHour {
    close_hour: string
    open_hour: string
    day_of_week_name: string
    day_of_week_num: number
}

export interface XLocationSpecialHour {
    close_hour: string
    open_hour: string
    closed: boolean
    special_date: string
}

export interface XLocationAddress {
    city: string
    metro_city: string
    latitude: number
    longitude: number
    country: string
    countryFull: string
    postalzip: string
    provstate: string
    provstateFull: string
    street: string
    suite: string
    phone: string
}

//NEW API

export interface RestaurantLocation extends RestaurantLocationMin {
    readonly name: string
    readonly currency: CurrencyCode
    readonly time_zone_name: string
    readonly square: SquareLocation
    readonly tips: TipConfig
    readonly menu: {
        external_url?: string
    }
    readonly address: Address
}

export interface RestaurantLocationMin {
    readonly id: number
    readonly full_name: string
}

interface SquareLocation {
    readonly location_id: string
    readonly app_id: string
}

interface TipConfig {
    readonly default_percent: number
    readonly percents: number[]
}

interface Address {
    readonly city: string
    readonly country: CountryCode
    readonly id: number
    readonly suite: string
    readonly street: string
    readonly delivery: {
        instructions: string
    }
    readonly metro_city: string
    readonly position: Coordinates
    readonly postal_zip: string
    readonly province_state: string
}
export type CountryCode = string

export type CurrencyCode = string

export interface RevenueCenterMin {
    readonly id: number
    readonly name: string
}

interface Coordinates {
    readonly latitude: number | null
    readonly longitude: number | null
}
