import { Injectable } from '@angular/core'
import type { XLogin, UserResponse } from './api/login.service'
import type { XDisplayCategory, XMenuItem } from './api/menu.service'
import type { XLocation } from './api/location.service'
import type { XStructureCheckDetail } from './api/check.service'
import { AngularFireAnalytics } from '@angular/fire/compat/analytics'
import type { Cart, GiftCardUsage } from './cart-service.service'
import type { SQCardDetails } from './widget/square-pay-form/types'

@Injectable({
    providedIn: 'root',
})
export class EventService {
    // https://support.google.com/firebase/topic/6317484
    // https://developers.google.com/gtagjs/reference/aw-events
    // https://developers.google.com/gtagjs/reference/event

    // GA4
    // https://support.google.com/analytics/answer/9267735?hl=en&ref_topic=9756175
    // https://developers.google.com/gtagjs/reference/ga4-events
    constructor(private analytics: AngularFireAnalytics) {
        // TODO for cookies tracking
        // this.analytics.setAnalyticsCollectionEnabled(false);
    }

    custom(name: string, obj: any) {
        try {
            this.analytics.logEvent(name, obj)
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    setLocation(l: XLocation) {
        try {
            this.analytics.updateConfig({
                location: l.name,
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    setOperation(l: string) {
        try {
            this.analytics.updateConfig({
                operation: l,
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    setBrand(brand: string) {
        try {
            this.analytics.updateConfig({
                brand: brand,
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    login(l: UserResponse) {
        try {
            this.setBrand(l.brand)

            this.analytics.setUserId('' + l.id)

            this.analytics.logEvent('login', {
                method: 'registered',
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    anonLogin(l: XLogin) {
        try {
            this.setBrand(l.brand)

            this.analytics.setUserId('' + l.id)

            if (l.login_status === 'returning') {
                // login	method
                this.analytics.logEvent('login', {
                    method: 'anon',
                })
            } else {
                // sign_up	method
                this.analytics.logEvent('sign_up', {
                    method: 'anon',
                })
            }
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    addToCart(item: XStructureCheckDetail, loc: XLocation) {
        // add_to_cart	value, currency, items
        try {
            this.analytics.logEvent('add_to_cart', {
                value: item.internal.totalWithChildrenXQty,
                currency: loc.currency,
                items: [this.buildGAItem(item, loc)],
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    removeFromCart(item: XStructureCheckDetail, loc: XLocation) {
        // remove_from_cart	value, currency, items
        try {
            this.analytics.logEvent('remove_from_cart', {
                value: item.internal.totalWithChildrenXQty,
                currency: loc.currency,
                items: [this.buildGAItem(item, loc)],
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    checkout(cart: Cart, step: number, option: string) {
        // checkout_progress  value, currency, items, coupon, checkout_step, checkout_option
        try {
            this.analytics.logEvent('checkout_progress', {
                value: cart.subTotal,
                currency: cart.location.currency,
                items: this.buildGAItems(cart),
                checkout_step: step,
                checkout_option: option,
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    addPaymentInfo(cart: Cart, cardDets: SQCardDetails) {
        // add_payment_info  coupon, value, currency, items, payment_type
        try {
            this.analytics.logEvent('add_payment_info', {
                value: cart.subTotal,
                currency: cart.location.currency,
                items: this.buildGAItems(cart),
                payment_type:
                    cardDets.card_brand + '-' + cardDets.digital_wallet_type,
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    addGCInfo(cart: Cart, dets: GiftCardUsage) {
        // add_payment_info  coupon, value, currency, items, payment_type
        try {
            this.analytics.logEvent('add_payment_info', {
                value: cart.subTotal,
                currency: cart.location.currency,
                items: this.buildGAItems(cart),
                payment_type: 'GiftCard',
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    paymentDone(txid: string, cart: Cart) {
        // purchase	transaction_id, value, currency, tax, shipping, items,coupon
        try {
            this.analytics.logEvent('purchase', {
                transaction_id: txid,
                value: cart.subTotal,
                currency: cart.location.currency,
                tax: cart.taxTotal,
                items: this.buildGAItems(cart),
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    beginCheckout(cart: Cart, loc: XLocation) {
        // begin_checkout	value, currency, items, coupon
        try {
            this.analytics.logEvent('begin_checkout', {
                value: cart.subTotal,
                currency: loc.currency,
                items: this.buildGAItems(cart),
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    selectContent(contentType: string, contentId: string) {
        //  select_content	items, promotions, content_type, content_id
        try {
            this.analytics.logEvent('select_content', {
                content_type: contentType,
                content_id: contentId,
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    selectItem(x: XMenuItem, dc: XDisplayCategory, loc: XLocation) {
        //  a user clicks on a product or product link for one or more products
        try {
            this.analytics.logEvent('select_item', {
                items: [this.buildGAItem2(x, loc)],
                item_list_name: dc.name,
                item_list_id: dc.id,
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    viewCart(cart: Cart) {
        // view_cart currency, items, value
        // when a user views their cart
        try {
            this.analytics.logEvent('view_cart', {
                value: cart.subTotal,
                currency: cart.location.currency,
                items: this.buildGAItems(cart),
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    viewItem(mi: XMenuItem, loc: XLocation) {
        // view_item	items
        // a user views product or item details
        try {
            this.analytics.logEvent('view_item', {
                items: [this.buildGAItem2(mi, loc)],
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    viewItemList(dc: XDisplayCategory, mis: XMenuItem[], loc: XLocation) {
        // view_item_list  items, item_list_name, item_list_id
        try {
            this.analytics.logEvent('view_item_list', {
                item_list_name: dc.name,
                item_list_id: dc.id,
                items: this.buildGAItems2(mis, loc),
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    exception(desc: string, isFatal: boolean) {
        // exception	description, fatal
        // an error occurs
        try {
            this.analytics.logEvent('exception', {
                description: desc,
                fatal: isFatal,
            })
        } catch (x) {
            console.log('EventServiceError', x)
        }
    }

    private buildGAItems(cart: Cart) {
        return cart.items.map((item) => this.buildGAItem(item, cart.location))
    }

    private buildGAItem(item: XStructureCheckDetail, loc: XLocation) {
        return {
            name: item.internal.mi.shortDesc,
            category: !!item.internal.mi.display
                ? item.internal.mi.display[0]?.name
                : '',
            id: item.internal.mi.miseq,
            quantity: item.quantity,
            location_id: loc.name,
            price: item.internal.mi.priceDollar,
        }
    }

    private buildGAItems2(mis: XMenuItem[], loc: XLocation) {
        return mis.map((item) => this.buildGAItem2(item, loc))
    }

    private buildGAItem2(mi: XMenuItem, loc: XLocation) {
        return {
            name: mi.shortDesc,
            category: !!mi.display ? mi.display[0]?.name : '',
            id: mi.miseq,
            location_id: loc.name,
            price: mi.priceDollar,
        }
    }
}
