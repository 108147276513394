// @ts-strict-ignore
import type { OnInit } from '@angular/core'
import { Component, Inject, Renderer2 } from '@angular/core'
import { environment } from '../../environments/environment'
import { DOCUMENT } from '@angular/common'
import { BehaviorSubject, Observable } from 'rxjs'
import { ScriptLoaderService } from '../script-loader.service'

//TODO -> confirm we can update squareURL in environment brand files
@Component({
    selector: 'app-script-loader',
    templateUrl: './script-loader.component.html',
    styleUrls: ['./script-loader.component.scss'],
})
export class ScriptLoaderComponent implements OnInit {
    constructor(
        @Inject(DOCUMENT) private document,
        private renderer2: Renderer2,
        private scriptService: ScriptLoaderService
    ) {}

    ngOnInit(): void {
        this.loadSquare()
        this.loadGoogle()
    }

    private loadSquare() {
        console.log('Loading Square!!!')
        const s = this.renderer2.createElement('script')
        s.type = 'text/javascript'
        s.id = 'sqPaymentFormScript'
        s.src = environment.squareUrl
        s.onload = () => {
            this.scriptService.squareLoaded$.next(true)
        }
        this.renderer2.appendChild(this.document.head, s)
    }

    private loadGoogle() {
        console.log('Loading Google!!!')
        const s = this.renderer2.createElement('script')
        s.type = 'text/javascript'
        s.id = 'googleMapsScript'
        s.src =
            'https://maps.googleapis.com/maps/api/js?key=' +
            environment.googleAPIKey +
            '&libraries=places&language=en'
        s.onload = () => {
            this.scriptService.googleLoaded$.next(true)
        }
        this.renderer2.appendChild(this.document.head, s)
    }
}
