<app-script-loader></app-script-loader>
<ngx-spinner
    bdColor="rgba(51,51,51,0.80)"
    color="#fff"
    type="ball-clip-rotate-pulse"
    fullScreen="true"
></ngx-spinner>
<app-nav
    [showLogo]="!isLanding"
    [title1]="titleService.getToolbar1()"
    [title2]="titleService.getToolbar2()"
>
</app-nav>
<div
    #routeContainer
    class="route-container"
    [@routeAnimation]="prepRouteState(outlet)"
    (@routeAnimation.start)="animationStart()"
    (@routeAnimation.done)="animationEnd()"
>
    <router-outlet #outlet="outlet"></router-outlet>
</div>
